// 项目所有请求接口地址以及icon图标地址
export const reqUrl = {
  url: {
    // title
    document_tile: 'بايان ھۆججەتلىك فىلىم',

    
    server_api: 'https://api.adaxtv.com/', // Dunya 接口


    // icon图标地址
    icon_url: 'https://dunya.atush.vip/static/icons/',
    // 图片地址
    image_url: 'https://dunya.atush.vip/static/image/',
    // vip_atush的 icon 地址
    vipAtushIconUrl: 'https://atush.vip/static/icons/',
    // vip_atush的 图片地址
    vipAtushIconImage: 'https://atush.vip/static/images/',
  }
}