<style>
.q-carousel__thumbnail {height: 30px !important;}
.fa-circle { font-size: 7px !important; }
.q-carousel__navigation--bottom { bottom: 0px !important; }
.q-carousel__navigation .q-btn { margin: 0px !important; }
</style>
<template>
  <div class="home bg-blue-grey-10">

    <!-- <div class="text-white-color" style="display: flex;justify-content: center; padding-top: 25px;" @click="clearSecc">清除缓存</div> -->

    <!-- 搜索 -->
    <div class="q-py-md q-px-sm bg-blue-grey-10">
      <q-input class="rtl Ukij q-px-sm border-r-10 bg-blue-grey-8" @focus="onFocus" borderless v-model="keyword" dense
        placeholder="ئىزدىمەكچى بولغان كىنو ئىسمىنى كىرگۈزۈڭ" />

      <!-- 搜索获取内容 -->
      <!-- <div v-if="searchOnFocus" class="relative-position"> -->
      <div v-if="keyword.length > 0" class="relative-position">
        <div class="bg-white q-pa-sm q-mb-md full-width searchConBox absolute z-index2 border-r-8 rtl overflow-y">
          <div v-for="item of searchData" class="border-b-2 row q-pa-sm" @click="onMovieItem(item)">
            <!-- 封面图 -->
            <div class="">
              <q-img :src="item.image_url" style="width: 50px;" />
            </div>

            <!-- 昵称 -->
            <div class="q-mx-md">
              <div class="Ukij">{{ item.name }}</div>
              <div class="Ukij">{{ item.name_zh }}</div>
              <div class="Ukij text-grey-6">{{ item.language_type == 1 ? 'ئۇيغۇرچە' : item.language_type == 2 ?
                'خەنزۇچە' : item.language_type == 3 ? 'ئەسلى'
                : item.language_type == 4 ? 'توپلام' : '' }}
              </div>
            </div>
            <div class="text-grey-5">({{ item.play_year}})</div>
          </div>
        </div>
        <div class="height_full_vh fixed full-width z-index1 fixed transparent-bg" @click="onTransparentBg"></div>
      </div>
    </div>

    <!-- 轮播图 -->
    <div class="q-pa-sm">
      <!-- 骨架 -->
      <div v-if="swiperListGujia">
        <q-skeleton class="height_170 full-width relative-position" />
      </div>

      <q-carousel v-else v-model="slide" swipeable thumbnails infinite animated :autoplay="3500"
        :transition-duration="3500" height="180px" style="border-radius: 15px;" transition-prev transition-next>
        <q-carousel-slide v-for="(item, index) in swiperList" :name="index" :key="index" :img-src="item.image_url"
          style="background-size: 100% 180px;" @click="onClickSwiper(item, 0)" />
      </q-carousel>
    </div>

    <!-- 公告 -->
    <div class="Ukij rtl q-pa-sm">
      <div class="bg-amber-10 q-px-md q-py-sm border-r-10 text-white Dflex align-center relative">
        <q-img style="width: 23px; transform: rotate(180deg);"
          :src="`${reqUrlData.url.vipAtushIconUrl}notice_home.svg`" />
        <q-carousel v-model=" slide2" vertical infinite animated :autoplay="3500" transition-duration="2000"
          transition-prev transition-next height="35px" class="full-width bg-amber-10">
          <q-carousel-slide class="column no-wrap flex-center" v-for="(item, index) of noticeArr" :name="index"
            :key="index">
            <div class="absolute font_size16" style="right: 10px;">
              {{ item.content }}
            </div>
          </q-carousel-slide>
        </q-carousel>
      </div>
    </div>

    <!-- 筛选选项 -->
    <div class="q-px-sm q-my-md rtl row">
      <q-intersection v-for="(item, index) in select_list" :key="index" transition="scale"
        class="example-item2 width_25p column items-center" @click="onClickSelect_list(item)">
        <div class="Dflex justify-center"><q-img class="q-mb-sm width_40 height_40" :src="item.icon" /></div>
        <div class="Ukij text-grey-2 text-center">{{item.name}}</div>
      </q-intersection>
    </div>

    <!-- 最近发布影片 -->
    <div class="q-px-sm rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">يېڭى يوللانغان كىنولار</div>
      </div>
    </div>
    <q-carousel v-model="slide3" swipeable transition-prev infinite transition-next :autoplay="3000" animated
      :transition-duration="3000" height="200px" class="background-none">
      <q-carousel-slide v-for="(group, index) in groupMoviesByThree(allMovie)" :name="index" class="column no-wrap">
        <div class="row fit justify-between items-center q-gutter-xs q-col-gutter no-wrap">
          <q-img v-for="(movie, subIndex) in group" :key="subIndex" style="width: 30% !important; height: 150px;"
            :src="movie.image_url" @click="onClickNewMovie(movie)" />
        </div>
      </q-carousel-slide>
    </q-carousel>

    <!-- 明星 -->
    <div class="q-px-sm q-mb-sm rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height"></div>
        <div class="Ukij text-grey-2 font_size20 q-mr-sm">چولپانلار</div>
      </div>
      <div class="row" @click="onClicActors">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <div class="rtl q-px-sm q-my-md Dflex nowrap overflow-scroll">
      <div class="column align-center q-ml-sm" v-for="(item, index) in actorDate" @click="onClickActor(item, index)">
        <q-img :class="actorActive == index ? 'border-b1-main' : ''" :src="item.image_url"
          style="width: 70px; height: 70px; border-radius: 50%;" />
        <div class="Ukij q-mt-sm text-ellipsis text-center"
          :class="actorActive == index ? 'text-main-color' : 'text-white'">{{
          item.name }}</div>
      </div>
    </div>

    <!-- 受欢迎 -->
    <div class="text-main-color Ukij text-center font_size28 e-mb-10">ئالقىشلىق كىنو فىلىملەر</div>
    <swiper :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" :slidesPerView="'auto'" :coverflowEffect="{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }" :autoplay="{
        delay: 1500,
        disableOnInteraction: false,
      }" :loop="true" :pagination="true" :modules="swiperModules" class="mySwiper q-px-sm q-mb-md bg-blue-grey-10">
      <swiper-slide class="width_130" v-for="item in popular" @click="onClickMovie(item, 5)">
        <img class="height_200" :src="item.image_url" />
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{item.name}}</div>
      </swiper-slide>

    </swiper>

    <!-- 动作 标题 -->
    <div class="q-px-sm rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">ھەركەتلىك</div>
      </div>
      <div class="row">
        <q-img :src="`${reqUrlData.url.icon_url}lianglie.svg`" style="width: 20px; height: 20px;" />
        <q-img :src="`${reqUrlData.url.icon_url}sanlie.svg`" class="q-mx-sm" style="width: 20px; height: 20px;" />
        <q-img :src="`${reqUrlData.url.icon_url}silie.svg`" style="width: 20px; height: 20px;" />
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color q-mr-sm" @click="onClickMore(1)">تېخىمۇ كۆپ ...
        </div>
      </div>
    </div>
    <!-- 动作片轮播图 -->
    <div class="q-pa-sm" v-if="swiperHomeData.actionData && swiperHomeData.actionData[0]">
      <q-carousel v-model="slide4" swipeable navigation infinite animated :autoplay="3500" :transition-duration="3500"
        height="100px" style="border-radius: 15px; font-size: 5px" transition-prev transition-next>
        <q-carousel-slide v-for="(item, index) in swiperHomeData.actionData" :name="index" :key="index"
          style="background-size: 100% 100px;" :img-src="item.image_url" @click="onClickSwiper(item, 0)" />
      </q-carousel>
    </div>
    <!-- 窗口 -->
    <div class="rtl q-px-sm q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width">
        <div v-for="item in 6" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieDate" v-for="(item, index) in actionMovie" :key="index" transition="scale"
        class="example-item width_30p q-mb-md box-list" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity text-orange-10 fontW-900 font_size12"
            :class="item.is_multiset == 1 ? 'e-t-0 e-l-0' : 'e-b-0 e-r-0'">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
          <div v-if="item.is_multiset == 1"
            class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{ item.name}}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year}}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row justify-between" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>
    <!-- 随机 -->
    <div class="row justify-center">
      <div class="bg-cyan-1 row align-center q-px-lg q-py-sm border-r-10" @click="onClickRefresh(1, 0, 1)">
        <img :class="rotatingIndex == 1 ? 'rotate-360' : ''" class="width_20"
          :src="`${reqUrlData.url.icon_url}refresh.svg`" />
        <div class="Ukij text-main-color q-ml-md font_size20">ئالماشتۇرۇش</div>
      </div>
    </div>

    <!-- 推荐 标题 -->
    <div class="q-px-sm q-mt-lg rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">تەۋسىيەلەر</div>
      </div>
      <div class="row" @click="onClicRecommend">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 推荐片轮播图 -->
    <div class="q-pa-sm" v-if="swiperHomeData.recommendData && swiperHomeData.recommendData[0]">
      <q-carousel v-model="slide5" swipeable navigation infinite animated :autoplay="3500" :transition-duration="3500"
        height="100px" style="border-radius: 15px;" transition-prev transition-next>
        <q-carousel-slide v-for="(item, index) in swiperHomeData.recommendData" :name="index" :key="index"
          style="background-size: 100% 100px;" :img-src="item.image_url" @click="onClickSwiper(item, 0)" />
      </q-carousel>
    </div>
    <!-- 窗口 -->
    <div class="rtl q-px-sm q-my-sm row justify-between">
      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width justify-between">
        <div v-for="item in 6" class="example-item1 width_48p q-mb-md">
          <q-skeleton class="height_100 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="recommendList.length > 0" v-for="(item, index) in recommendList" :key="index"
        transition="scale" class="example-item1 width_48p q-mb-md" @click="onClickMovie(item, 5)">
        <div class="height_100 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity text-orange-10 fontW-900 font_size12"
            :class="item.is_multiset == 1 ? 'e-t-0 e-l-0' : 'e-b-0 e-r-0'">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
          <div v-if="item.is_multiset == 1"
            class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{item.name}}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year}}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row justify-between" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>
    <!-- 随机 -->
    <div class="row justify-center">
      <div class="bg-cyan-1 row align-center q-px-lg q-py-sm border-r-10" @click="onClickRefresh(0, 1, 2)">
        <img :class="rotatingIndex == 2 ? 'rotate-360' : ''" class="width_20"
          :src="`${reqUrlData.url.icon_url}refresh.svg`" />
        <div class="Ukij text-main-color q-ml-md font_size20">ئالماشتۇرۇش</div>
      </div>
    </div>

    <!-- 科幻 标题 -->
    <div class="q-px-sm q-mt-lg rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">فانتازىيەلىك</div>
      </div>
      <div class="row" @click="onClickMore(2)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 科幻片轮播图 -->
    <div class="q-pa-sm" v-if="swiperHomeData.sciencefictionData && swiperHomeData.sciencefictionData[0]">
      <q-carousel v-model="slide6" swipeable navigation infinite animated :autoplay="3500" :transition-duration="3500"
        height="100px" style="border-radius: 15px;" transition-prev transition-next>
        <q-carousel-slide v-for="(item, index) in swiperHomeData.sciencefictionData" :name="index" :key="index"
          style="background-size: 100% 100px;" :img-src="item.image_url" @click="onClickSwiper(item, 0)" />
      </q-carousel>
    </div>
    <!-- 窗口 -->
    <div class="rtl q-px-sm q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width">
        <div v-for="item in 6" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieDate" v-for="(item, index) in newMovie" :key="index" transition="scale"
        class="example-item width_30p q-mb-md box-list" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity text-orange-10 fontW-900 font_size12"
            :class="item.is_multiset == 1 ? 'e-t-0 e-l-0' : 'e-b-0 e-r-0'">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
          <div v-if="item.is_multiset == 1"
            class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row justify-between" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>
    <!-- 随机 -->
    <div class="row justify-center">
      <div class="bg-cyan-1 row align-center q-px-lg q-py-sm border-r-10" @click="onClickRefresh(2, 0, 3)">
        <img :class="rotatingIndex == 3 ? 'rotate-360' : ''" class="width_20"
          :src="`${reqUrlData.url.icon_url}/refresh.svg`" />
        <div class="Ukij text-main-color q-ml-md font_size20">ئالماشتۇرۇش</div>
      </div>
    </div>

    <!-- 喜剧 标题 -->
    <div class="q-px-sm q-mt-lg rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">كومىدىيە</div>
      </div>
      <div class="row" @click="onClickMore(6)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 喜剧片轮播图 -->
    <div class="q-pa-sm" v-if="swiperHomeData.comedyData && swiperHomeData.comedyData[0]">
      <q-carousel v-model="slide7" swipeable navigation infinite animated :autoplay="3500" :transition-duration="3500"
        height="100px" style="border-radius: 15px;" transition-prev transition-next>
        <q-carousel-slide v-for="(item, index) in swiperHomeData.comedyData" :name="index" :key="index"
          style="background-size: 100% 100px;" :img-src="item.image_url" @click="onClickSwiper(item, 0)" />
      </q-carousel>
    </div>
    <!-- 窗口 -->
    <div class="rtl q-px-sm q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width">
        <div v-for="item in 6" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieDate" v-for="(item, index) in drama" :key="index" transition="scale"
        class="example-item width_30p q-mb-md box-list" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <span>is_multiset:</span>{{ item.is_multiset }}
          <div class="row absolute bg-opacity text-orange-10 fontW-900 font_size12"
            :class="item.is_multiset == 1 ? 'e-t-0 e-l-0' : 'e-b-0 e-r-0'">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
          <div v-if="item.is_multiset == 1"
            class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row justify-between" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>
    <!-- 随机 -->
    <div class="row justify-center">
      <div class="bg-cyan-1 row align-center q-px-lg q-py-sm border-r-10" @click="onClickRefresh(6, 0, 4)">
        <img :class="rotatingIndex == 4 ? 'rotate-360' : ''" class="width_20"
          :src="`${reqUrlData.url.icon_url}refresh.svg`" />
        <div class="Ukij text-main-color q-ml-md font_size20">ئالماشتۇرۇش</div>
      </div>
    </div>

    <!-- 卡通 标题 -->
    <div class="q-px-sm q-mt-lg q-mb-sm rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">كارتون</div>
      </div>
      <div class="row" @click="onClickMore(8)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 卡通片轮播图 -->
    <div class="q-pa-sm" v-if="swiperHomeData.cartoonData && swiperHomeData.cartoonData[0]">
      <q-carousel v-model="slide8" swipeable navigation infinite animated :autoplay="3500" :transition-duration="3500"
        height="100px" style="border-radius: 15px;" transition-prev transition-next>
        <q-carousel-slide v-for="(item, index) in swiperHomeData.cartoonData" :name="index" :key="index"
          style="background-size: 100% 100px;" :img-src="item.image_url" @click="onClickSwiper(item, 0)" />
      </q-carousel>
    </div>
    <!-- 窗口 -->
    <div class="rtl q-px-sm row">
      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width">
        <div v-for="item in 6" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieDate" v-for="(item, index) in cartoon" :key="index" transition="scale"
        class="example-item width_30p q-mb-md box-list" @click="onClickMovie(item, 5)">
        <div class="height_150 relative-position overflow-h border-r-8">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity text-orange-10 fontW-900 font_size12"
            :class="item.is_multiset == 1 ? 'e-t-0 e-l-0' : 'e-b-0 e-r-0'">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
          <div v-if="item.is_multiset == 1"
            class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{item.name}}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year}}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row justify-between" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>
    <!-- 随机 -->
    <div class="row justify-center">
      <div class="bg-cyan-1 row align-center q-px-lg q-py-sm border-r-10" @click="onClickRefresh(8, 0, 5)">
        <img :class="rotatingIndex == 5 ? 'rotate-360' : ''" class="width_20"
          :src="`${reqUrlData.url.icon_url}refresh.svg`" />
        <div class="Ukij text-main-color q-ml-md font_size20">ئالماشتۇرۇش</div>
      </div>
    </div>

    <!-- 所有 标题 -->
    <div class="q-px-sm q-mt-md q-mb-sm rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">بارلىق كىنولار</div>
      </div>
      <div class="row" @click="onClickAllMovie">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 窗口 -->
    <div class="rtl q-px-sm row e-mb-100" v-scroll="onScroll" ref="scrollFun">
      <!-- 骨架 -->
      <div v-if="allMovieGuJia" class="rtl q-px-sm q-my-sm row full-width">
        <div v-for="item in 6" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!allMovieGuJia" v-for="(item, index) in allMovie" :key="index" transition="scale"
        class="example-item width_30p q-mb-md box-list" @click="onClickMovie(item, 5)">
        <div class="height_150 relative-position overflow-h border-r-8">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity text-orange-10 fontW-900 font_size12"
            :class="item.is_multiset == 1 ? 'e-t-0 e-l-0' : 'e-b-0 e-r-0'">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
          <div v-if="item.is_multiset == 1"
            class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row justify-between" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>

    <!-- 返回顶部 -->
    <div v-if="showBackToTop" class="back-to-top">
      <div @click="scroll_top">
        <img :src="`${reqUrlData.url.icon_url}toTop.svg`" style="width: 50px; height: 50px" />
      </div>
    </div>

    <!-- 防止白底 -->
    <div class="bg-blue-grey-10 z-index-1 fixed height_500 e-b-0 full-width"></div>

    <!-- Tab -->
    <Tab tab_active="0"></Tab>
  </div>
</template>

<script>
import Tab from '../components/tab/Tab.vue';
import { ref } from 'vue';
import { getuserInfo, reqIndex, reqLogin, getIdMovieSelect, getHouse, getActors, getSwiperHome, getRandomMovie } from '../request/api.js';
import { debounce } from 'quasar';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import { useHomeStore, useHomeCategorySwiperStore, useHomeActorStore } from '../store/homeStore.js';
import { reqUrl } from '../request/url.js';

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    Tab
  },
  data() {
    return {
      keyword: '',
      slide: ref(0),
      slide2: ref(0),
      slide3: ref(0),
      slide4: ref(0),
      slide5: ref(0),
      slide6: ref(0),
      slide7: ref(0),
      slide8: ref(0),
      // autoplay: true,
      reqUrlData: reqUrl,
      select_list: [
        { id: 0, name: 'ئەڭ يېڭى', icon: `${reqUrl.url.icon_url}kinolar.svg`},
        { id: 1, name: 'تەۋسىيە', icon: `${reqUrl.url.icon_url}recommend1.svg`},
        { id: 2, name: 'ئالقىشلىق', icon: `${reqUrl.url.icon_url}hottest.svg`},
        { id: 3, name: 'چولپانلار', icon: `${reqUrl.url.icon_url}kino2.svg`},
      ],
      newMovie: useHomeStore().newMovie,
      swiperList: useHomeStore().swiperList,
      cartoon: useHomeStore().cartoon,
      actionMovie: useHomeStore().actionMovie,
      recommendList: useHomeStore().recommendList,
      popular: useHomeStore().popular,
      drama: useHomeStore().drama,
      allMovie: useHomeStore().allMovie,
      searchData: [],
      noticeArr: useHomeStore().noticeArr,
      actorDate: useHomeActorStore().actorDate,
      swiperHomeData: useHomeCategorySwiperStore().swiperHomeData,
      movieDate: useHomeStore().movieDate, // 电影骨架
      searchOnFocus: false, // 激活搜索
      allMovieGuJia: useHomeStore().allMovieGuJia, // 激活搜索
      isRequesting: false, // 激活搜索
      isRotating: false,
      showBackToTop: false,
      swiperListGujia: useHomeStore().swiperListGujia,
      rotatingIndex: 0,
      actorActive: 0,
      pageNo: 1,
      pageSize: 9,
      totalPage: null,
      is_multiset: -1,
      status: 1,
      countryId: -1,
      recommend: -1,
      popularNum: -1,
      is_free: -1,
      play_year: -1,
      language_type: -1,
      categoryId: -1,
      swiperModules: [Autoplay, EffectCoverflow],
      homeStore: useHomeStore()
    }
  },
  watch: {
    keyword() {
      this.searchCon(this.keyword);
    },
    pageNo (val) {
      this.pageNo = val;
      this.loading = true;
      this.getAllMovie();
    }
  },
  methods: {
    // 清除缓存
    clearSecc() {
      localStorage.clear();
    },
    
    //首页请求
    async getIndex() {

      let swiperList = this.homeStore.swiperList;
      let newMovie = this.homeStore.newMovie;
      let cartoon = this.homeStore.cartoon;
      let actionMovie = this.homeStore.actionMovie;
      let recommendList = this.homeStore.recommendList;
      let popular = this.homeStore.popular;
      let drama = this.homeStore.drama;
      let noticeArr = this.homeStore.noticeArr;
      let allMovie = this.homeStore.allMovie;
      let movieDate = this.homeStore.movieDate;

      if(swiperList.length > 0 || newMovie.length > 0 || cartoon.length > 0 || actionMovie.length > 0 || recommendList.length > 0 || popular.length >0 || drama.length > 0 || noticeArr.length > 0 || allMovie.length > 0 || !movieDate) return;

      await reqIndex().then(res => {
        if(res.status == 200) {
          this.newMovie = res.data.house.data
          this.swiperList = res.data.swipers.data
          this.cartoon = res.data.cartoon.data
          this.actionMovie = res.data.action.data
          this.recommendList = res.data.recommend.data
          this.popular = res.data.popular.data
          this.drama = res.data.drama.data
          this.noticeArr = res.data.notice.data
          this.movieDate = false;
          this.swiperListGujia = false;

          // 写入 Pinia 数据
          useHomeStore().newMovie = res.data.house.data
          useHomeStore().swiperList = res.data.swipers.data
          useHomeStore().cartoon = res.data.cartoon.data
          useHomeStore().actionMovie = res.data.action.data
          useHomeStore().recommendList = res.data.recommend.data
          useHomeStore().popular = res.data.popular.data
          useHomeStore().drama = res.data.drama.data
          useHomeStore().noticeArr = res.data.notice.data
          useHomeStore().movieDate = false;
          useHomeStore().swiperListGujia = false;
        }
      }).catch(err => {
        console.log('err: ', err);
      })

      this.getAllMovie();
    },

    // 获取首页轮播图
    filterSwiperHome() {
      if(Object.keys(useHomeCategorySwiperStore().swiperHomeData).length > 0) return;
      getSwiperHome().then(res => {
        if(res.status == 200) {
          this.swiperHomeData = res.data;
          useHomeCategorySwiperStore().swiperHomeData = res.data; // 写入 Pinia 数据
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },

    // 获取全部电影
    getAllMovie() {
      this.isRequesting = true;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let is_multiset = this.is_multiset;
      let status = this.status;
      let countryId = this.countryId;
      let recommend = this.recommend;
      let popular = this.popularNum;
      let is_free = this.is_free;
      let play_year = this.play_year;
      let language_type = this.language_type;
      let categoryId = this.categoryId;

      getHouse(pageNo, pageSize, status, countryId, is_multiset, recommend, popular, is_free, play_year, language_type, categoryId).then(res => {
        if(res.status == 200) {
          this.allMovie = this.allMovie.concat(res.data.data);
          useHomeStore().allMovie = useHomeStore().allMovie.concat(res.data.data); // 写入 Pinia 数据
          this.totalPage = res.data.totalPage;
          this.allMovieGuJia = false;
          useHomeStore().allMovieGuJia = false; // 写入 Pinia 数据
          this.isRequesting = false;
        }
      }).catch(err => { console.log("err: ", err) })
    },

    // 点击轮播图
    onClickSwiper (item, type){
      let data = {
        type: type,
        id: item.content_id
      }

      this.$router.push({ path: '/detailed', query: data })
    },

    // 点击选项
    onClickSelect_list(item) {
      switch (item.id) {
        case 0:
          this.$router.push({ path: '/newmovie' })
          break;
        case 1:
          this.$router.push({ path: '/recommend' })
          break;
        case 2:
          this.$router.push({ path: '/popular' })
          break;
        case 3:
          this.$router.push({ path: '/sets' })
          break;
        default:
          console.log("出错 ~")
          break;
      }
    },

    // 点击更多
    onClickMore (category) {
      let data = {
        category: category
      }
      this.$router.push({ path: '/more', query: data })
    },

    // 点击电影
    onClickMovie(item, type) {
      let data = {
        type: type,
        id: item.id,
        category: item.category[0].id
      }
      this.$router.push({ path: '/detailed', query: data })
    },

    // 滚动到顶部
    scroll_top () {
      // window.scrollTo(0, 0);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动至顶部
    },

    // 登录请求
    reqLoginFun() {
      reqLogin().then(res => {
        if (res.status == 200) {
          window.location.href = res.data
        }
      }).catch()
    },

    // 把 code 和 用户信息 存到缓存
    isCode() {
      if (window.location.search.includes('code=')) {
        localStorage.setItem('isCode', this.$route.query.code)
        let code = this.$route.query.code
        let data = { code }
        getuserInfo(data).then(res => {
          const userInfo = res.data.data
          const token = res.data.token
          localStorage.setItem("userInfo", JSON.stringify(userInfo))
          localStorage.setItem("token", token)
        }).catch()
      }
      return localStorage.getItem("isCode") !== null
    },

    // 激活搜索
    onFocus() {
      this.searchOnFocus = true;
    },

    // 透明背景
    onTransparentBg() {
      this.searchOnFocus = false;
    },

    // 点击搜索结果电影
    onMovieItem(item) {
      let data = {
        id: item.id
      }
      this.$router.push({ path: '/detailed', query: data })
      this.searchOnFocus = false;
    },

    // 获取搜索内容
    searchCon(event) {
      this.keyword.length;
      if(this.keyword.length == 0) return this.searchData = '';
      let keyWord = event;
      getIdMovieSelect(keyWord).then(res => {
        if(res.status == 200) {
          this.searchData = res.data
        }
      }).catch(error => {
        console.log("error: ", error)
      })
    },

    // 新发布的影片分三组
    groupMoviesByThree(movies) {
      const groups = [];
      for (let i = 0; i < movies.length; i += 3) {
        groups.push(movies.slice(i, i + 3));
      }
      return groups;
    },

    // 点击新电影
    onClickNewMovie(item) {
      let data = {
        id: item.id,
        category: item.category[0].id
      }
      this.$router.push({ path: '/detailed', query: data })
    },

    onScroll: debounce(function (position) {
      let scrollTop = this.$refs.scrollFun.scrollTop;
      let clientHeight = this.$refs.scrollFun.clientHeight;
      let scrollHeight = this.$refs.scrollFun.scrollHeight;
      let height = 400;
      if (scrollTop + clientHeight >= scrollHeight - height) {
        if (this.pageNo === this.totalPage) {
          return false
        } else if (this.pageNo < this.totalPage) {
          this.pageNo = this.pageNo + 1
        }
      } else {
        return false
      }
    }, 200),

    // 获取演员
    getActor () {
      if(useHomeActorStore().actorDate.length > 0) return;
      let sex = -1;
      let nationality = -1;
      let pageNo = 1;
      let pageSize = 15;
      getActors(pageNo, pageSize, sex, nationality).then(res => {
        if(res.status == 200) {
          this.actorDate = res.data.data;
          useHomeActorStore().actorDate = res.data.data; // 写入 Pinia 数据
        }
      })
    },

    // 往演员电影页面
    onClickActor(item, index) {
      this.actorActive = index;
      setTimeout(() => {
        this.$router.push({ path: '/star', query: { postId: item.id } });
      }, 500);
    },

    // 往演员页面
    onClicActors() {
      this.$router.push({ path: '/sets' })
    },

    // 往所有电影页面
    onClickAllMovie() {
      this.$router.push({ path: '/more' })
    },

    // 往推荐电影页面
    onClicRecommend() {
      this.$router.push({ path: '/recommend' })
    },

    // 随机获取电影
    onClickRefresh(item, index, rotatingIndex) {
      this.rotatingIndex = rotatingIndex;
      this.isRotating = true;

      if(index == 1) {
        let pageSize = 4;
        let recommend = 1;
        let categoryId = -1;
        getRandomMovie(pageSize, recommend, categoryId).then(res => {
          
          if(res.status == 200) {
            this.recommendList = res.data;
          }
        }).catch( err => { console.log("err: ", err) } )
      } else {
        let pageSize = 6;
        let recommend = -1;
        let categoryId = item;
        getRandomMovie(pageSize, recommend, categoryId).then(res => {
          if(res.status == 200) {
            switch (item) {
              case 1:
                this.actionMovie = res.data;
                break;
              case 2:
                this.newMovie = res.data;
                break;
              case 2:
                this.house = res.data;
                break;
              case 6:
                this.drama = res.data;
                break;
              case 8:
                this.cartoon = res.data;
                break;
              default:
                break;
            }
          }
        }).catch(err => { console.log("err: ", err) })
      }
      
      // 在这里添加其他逻辑
      if (this.isRotating) {
        setTimeout(() => {
          this.isRotating = false;
          this.rotatingIndex = 0;
        }, 1000); // 假设你的过渡效果是1秒
      }
    },

    // 监听滚动事件
    handleScroll () {
      if (window.scrollY > 700) { // 当滚动超过700px时显示按钮
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }
    },

  },
  created() {
    this.scroll_top()
    if (!this.isCode()) this.reqLoginFun();
    this.getIndex()
    this.getActor()
    this.filterSwiperHome(); // 获取筛选轮播图
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
@import "../assets/style/style.css";

.background-none { background-color: transparent !important; }

.transparent-bg {
  top: 60px;
  background-color: rgba(0, 0, 0, 0.8);
}

.searchConBox {
  height: 450px;
}
.title_right {
  width: 5px;
  border-radius: 50px;
}
.box-list {
  margin-left: 5%;
}
.box-list:nth-child(3n) {
  margin-left: 0;
}
.example-item {height: 210px;}
.example-item1 {height: 160px;}
.example-item2 {height: 70px;}
.swiper {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  /* width: 400px; */
  /* height: 300px; */
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.rotate-360 {
  transition: transform 1s;
  transform-origin: center center;
  transform: rotate(360deg);
}

.back-to-top {
  position: fixed;
  bottom: 140px;
  right: 20px;
  /* display: none; */
  /* 默认隐藏 */
  background-color: #f44336;
  width: 50px;
  height: 50px;
  /* padding: 10px 20px; */
  border-radius: 50%;
  z-index: 2;
}
</style>
